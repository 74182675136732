.video {
  margin-bottom: var(--indent-gap-section);
}

/* Start media queries  */
@media screen and (max-width: 768px) {
  .video {
    margin-bottom: var(--mobile-indent-gap-section);
  }
}
/* End media queries  */
