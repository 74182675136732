.portfolio-filter {
  margin-top: var(--indent-gap-section);
  margin-bottom: var(--indent-gap-section);

  h2 {
    text-align: center;
    margin-bottom: var(--indent-gap-section);
    padding: 0 var(--indent-container);
  }

  .portfolio-filter_item_main {
    display: flex;
    flex-direction: column;
    gap: var(--indent-gap-section);
  }
}


/* Start media queries  */
@media screen and (max-width: 768px) {
  .portfolio-filter {
    margin-top: var(--mobile-indent-gap-section);
    margin-bottom: var(--mobile-indent-gap-section);

    h2 {
      font-size: calc((100vw - 48px)/13);
      margin-bottom: var(--mobile-indent-gap-section);
    }

    .portfolio-filter_item_main {
      gap: var(--mobile-indent-gap-section);
    }
  }
}

@media (min-width: 768px) and (max-width: 1350px) {
  .portfolio-filter {
    h2 {
      font-size: calc((100vw - 48px)/13);
    }
  }
}
/* End media queries  */