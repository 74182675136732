.footer,
.page-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-top: 1px solid var(--border-color);

  &.menu-footer {
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 0;
  }
}
