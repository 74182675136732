:root {
  --max-container-width: 2500px;
  --indent-container: 30px;
  --indent-gap-section: 150px;
  --indent-gap-section-2: 80px;
  --indent-gap-section-footer: 100px;

  --color-background: var(--dark);
  --color-text: var(--light);

  --text-font: 'AllRoundGothicW01-XLig';
  --title-font: 'FuturaPTDemi';

  --theme-letter-spacing: 1px;

  --primary-color: #FF7F50;
  --dark: #0A0908;
  --light: #EAEAEA;
  --gray: #51596C;
  --teal: #077C76;
  --blue: #334AC0;
  --yellow: #F1B980;
  --red: #692340;

  --link-hover: #D7714B;
  --glitch-before: #FF7F50;
  --glitch-after: #ffb900;
  --glitch-before-active: #333333;
  --glitch-after-active: var(--light);
  --socials-item: rgba(217, 217, 217, 0.1);
  --socials-item-hover: rgba(217, 217, 217, 0.2);
  --border-color: rgba(217, 217, 217, 0.1);

  --size-text: 22px;
  --size-h1: 157px;
  --size-h2: 100px;
  --size-h3: 80px;
  --size-h4: 50px;
  --size-h5: 35px;
  --size-h6: 25px;

  --glitch-text: 100px;
  --glitch-text1: 80px;
  --glitch-text2: 50px;
  --glitch-text3: 25px;

  /* Mobile */

  --mobile-indent-gap-section: 50px;
  --mobile-indent-container: 10px;
  --fs-mobile-menu-links: 50px;
  --fs-mobile-h1: 38px;
  --fs-mobile-text: 18px;
  --fs-text1: 25px;
}


/* Start app general styles */
a {
  text-decoration: none;
  color: var(--light);
  font-weight: inherit;
}

a:hover {
  color: var(--link-hover);
}

a:hover svg path {
  fill: var(--link-hover);
}

a.active {
  color: var(--link-hover);
}

a.active::before {
  color: var(--dark);
}

a.active::after {
  color: var(--light);
}

a.hover-glitch:hover {
  color: inherit;
}

a.hover-glitch.active:hover {
  color: var(--link-hover);
}

img {
  width: 100%;
}

#footer-connect .etsySVG {
  position: relative;
  top: 3px;
  left: 2px;
}
/* End app general styles */

/* Start menu animate */
.menu-footer-animate {
  opacity: 0;
}
/* End menu animate */

/* Start canvas */
.canvas-container {
  position: relative;
}

.canvas-container img,
.canvas-container canvas {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
/* End canvas */

/* Start animate cursor */
.cursors-hover div span.visible {
  opacity: 1;
}
/* End animate cursor */

/* Start media queries  */
@media screen and (max-width: 768px) {
  .canvas-container img,
  .canvas-container canvas {
    height: 60vh;
  }
}
/* End media queries  */
