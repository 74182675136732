nav {
  background: transparent;
  padding: 0 var(--indent-container);
}

.navigation {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  z-index: 10;

  .navigation-menu {
    button {
      position: relative;
      z-index: 2;
      outline: none;
      border: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      cursor: pointer;
      width: 56px;
      height: 50px;
      border-radius: 50%;
      background: transparent;
      padding: 0;
    }
    
    button path {
      fill: var(--color-text);
      will-change: transform;
    }
  }
}

/* Start media queries  */
@media screen and (max-width: 768px) {
  .navigation {
    padding: var(--mobile-indent-container);
  
    .navigation-logo {
      img {
        max-width: 85px;
      }
    }
  }
}
/* End media queries  */
