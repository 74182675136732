.header-home-page {
  height: 100vh;
  width: 100%;
  position: relative;

  .header-home-page-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 0 var(--indent-container);
    z-index: 2;

    h1 {
      line-height: 110px;

      span {
        color: var(--primary-color);
      }
    }

    .header-home-page-decription {
      display: flex;
      justify-content: center;
      gap: 300px;
      top: 0;
      color: var(--primary-color);
    }
  }
}

/* Start media queries  */
@media screen and (max-width: 768px) {
  .header-home-page {
    height: 60vh;

    .header-home-page-content {
      padding: 0 var(--mobile-indent-container);
  
      h1 {
        font-size: calc((100vw - 48px)/8);
        line-height: 65px;
  
        span {
          color: var(--primary-color);
        }
      }
  
      .header-home-page-decription {
        font-size: var(--fs-text1);
        gap: 5px;
        flex-direction: column;
        max-width: 295px;
        margin: 0 auto;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1350px) {
  .header-home-page {
    .header-home-page-content {
      h1 {
        font-size: calc((100vw - 48px)/8);
      }
  
      .header-home-page-decription {
        font-size: calc((100vw - 48px)/35);
        gap: calc((100vw - 48px)/8);
      }
    }
  }
}
/* End media queries  */
