@import './product-detail-section';

.portfolio-filter_item_main {
  position: relative;
  width: 100%;
  transition: transform 1s;

  &:hover {
    transform: rotate(1deg);
  }

  .portfolio-filter_item {
    max-width: 964px;
    margin: 0 auto;
    position: relative;
  
    .portfolio-filter_item_tag {
      position: absolute;
      top: 30px;
      left: -65px;
      text-transform: uppercase;
      color: var(--primary-color);
      background: var(--color-background);
      width: max-content;
      padding: 20px;
      border: 1px solid var(--primary-color);
    }
  
    .portfolio-filter_item_img {
      max-height: 1100px;
      overflow: hidden;
      height: 970px;
  
      img {
        max-height: 1100px;
        object-fit: cover;
        height: 100%;
      }
    }
  }

  .portfolio-filter_item_ticker {
    width: 100%;
    position: absolute;
    bottom: 40px;
  }
}

/* Start media queries  */
@media screen and (max-width: 1439px) {
  .portfolio-filter_item_main {
    &:hover {
      transform: inherit;
    }

    .portfolio-filter_item_ticker {
      bottom: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .portfolio-filter_item_main {
    .portfolio-filter_item {
      .portfolio-filter_item_tag {
        left: 0;
        font-size: calc((100vw - 48px)/20);
        padding: 10px;
      }

      .portfolio-filter_item_img {
        height: 570px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1350px) {
  .portfolio-filter_item_main {
    .portfolio-filter_item {
      .portfolio-filter_item_tag {
        left: 0;
      }
    }
  }
}
/* End media queries  */
