/* Start general styles */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  max-width: var(--max-container-width);
  margin: 0 auto;
  background: var(--color-background);
  color: var(--color-text);
  font-size: var(--size-text);
  font-weight: inherit;
  letter-spacing: var(--theme-letter-spacing);
  font-family: var(--text-font);
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--title-font);
  font-weight: 100;
}

h1 {
  font-size: var(--size-h1);
}

h2 {
  font-size: var(--size-h2);
}

h3 {
  font-size: var(--size-h3);
}

h4 {
  font-size: var(--size-h4);
}

h5 {
  font-size: var(--size-h5);
}

h6 {
  font-size: var(--size-h6);
}

p {
  color: var(--light);
  font-size: var(--size-text);
  line-height: 32px;
}

ul,
li {
  list-style: none;
  margin: 0;
  font-weight: inherit;
}

.da-container {
  padding-left: var(--indent-container);
  padding-right: var(--indent-container);
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
/* End general styles */

/* Start fonts */
@font-face {
  font-family: 'FuturaPTDemi';
  src: url('./fonts/futura-pt/FuturaPT-Demi.eot');
  src: url('./fonts/futura-pt/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
      url('./fonts/futura-pt/FuturaPT-Demi.woff2') format('woff2'),
      url('./fonts/futura-pt/FuturaPT-Demi.woff') format('woff'),
      url('./fonts/futura-pt/FuturaPT-Demi.ttf') format('truetype'),
      url('./fonts/futura-pt/FuturaPT-Demi.svg#FuturaPT-Demi') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AllRoundGothicW01-XLig';
  src: url('./fonts/all-round-gothic/AllRoundGothicW01-XLig.eot');
  src: url('./fonts/all-round-gothic/AllRoundGothicW01-XLig.eot?#iefix') format('embedded-opentype'),
      url('./fonts/all-round-gothic/AllRoundGothicW01-XLig.woff2') format('woff2'),
      url('./fonts/all-round-gothic/AllRoundGothicW01-XLig.woff') format('woff'),
      url('./fonts/all-round-gothic/AllRoundGothicW01-XLig.ttf') format('truetype'),
      url('./fonts/all-round-gothic/AllRoundGothicW01-XLig.svg#AllRoundGothicW01-XLig') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
/* End fonts */

/* Start media queries  */
@media screen and (max-width: 768px) {
  p {
    font-size: var(--fs-mobile-text);
  }
}
/* End media queries  */
