/* Start main menu styles */
.menu {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--color-background);
  transform: translateX(300%);
  will-change: transform;
}
/* End main menu styles */
