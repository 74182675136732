.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-transform: uppercase;
  padding: 0 var(--indent-container);
  text-align: center;

  h1 {
    span {
      color: var(--primary-color);
    }
  }
}

/* Start media queries  */
@media screen and (max-width: 768px) {
  .title {
    height: 50vh;

    h1 {
      font-size: calc((100vw - 48px)/8);
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .title {
    h1 {
      font-size: calc((100vw - 48px)/8);
    }
  }
}
/* End media queries  */
