@import '../globalStyles/glitch-active';

ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  text-align: center;

  li {
    color: var(--color-text);
    display: block;
    padding: 10px;
    will-change: transform, opacity, filter;
    transform: scale(0);
    filter: blur(0px);
    font-size: var(--size-h2);
    font-family: var(--title-font);
    text-transform: uppercase;
    font-weight: inherit;

    a {
      position: relative;
    }
  }
}

ul.nav-footer {
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  li {
    transform: scale(1);
  }
}

/* Start menu hover Glitch effect */
.glitch-active-1 {
  &::after,
  &::before {
    content: 'HOME';
  }
}

.glitch-active-2 {
  &::after,
  &::before {
    content: 'ABOUT US';
  }
}

.glitch-active-3 {
  &::after,
  &::before {
    content: "LET'S TALK";
  }
}
/* End menu hover Glitch effect */

/* Start media queries  */
@media screen and (max-width: 768px) {
  ul {
    padding: 30px 10px;

    li {
      padding: 0;
      font-size: var(--fs-mobile-menu-links);

      .glitch-active {
        &::before,
        &::after {
          font-size: var(--glitch-text2);
          letter-spacing: 0.8px;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  ul.nav-footer {
    li {
      font-size: var(--size-h6);

      .glitch-active {
        &::before,
        &::after {
          font-size: var(--glitch-text3);
          letter-spacing: 0.8px;
        }
      }
    }
  }
}
/* End media queries  */
