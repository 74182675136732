.stay-connected {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: var(--indent-container);
  margin-bottom: var(--indent-gap-section-footer);

  h2 {
    text-transform: uppercase;
    text-align: center;
  }

  .stay-connected-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;

    h3 {
      color: var(--primary-color);
    }
  }
}

/* Start media queries  */
@media screen and (max-width: 1350px) {
  .stay-connected {
    margin-bottom: var(--mobile-indent-gap-section);
    gap: var(--mobile-indent-gap-section);
    h2 {
      font-size: calc((100vw - 48px) / 13);
    }
  
    .stay-connected-content {
      flex-direction: column;
      gap: 30px;
  
      h3 {
        font-size: calc((100vw - 48px) / 13);
      }
    }
  }
}
/* End media queries  */