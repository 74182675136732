.ticker-wrap {
  display: flex;
  width: 100%;
  box-shadow: 0 8px 16px rgba(#000000, 0.5);
  overflow: hidden;
  background: rgba(10, 9, 8, 0.5);
  
  .ticker-wrap_text {
    display: flex;
    align-items: center;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    color: var(--color-text);
    animation: animate-ticker 40s -40s linear infinite;
    will-change: transform;
    
    &:nth-child(2) {
      animation: animate-ticker2 40s -20s linear infinite;
    }

    h2 {
      padding: 0 5px;
      margin: 0;
    }

    span {
      font-size: var(--size-h1);
      padding: 0;
      margin: 0;
    }
  }
}

@keyframes animate-ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate-ticker2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

/* Start media queries  */
@media screen and (max-width: 1439px) {
  .ticker-wrap {
    height: auto;

    .ticker-wrap_text {
      &:first-child {
        display: none;
      }

      &:nth-child(2) {
        animation: inherit;
        white-space: break-spaces;
      }

      h2 {
        font-size: var(--size-h5);
      }

      span {
        display: none;
      }
    }
  } 
}

@media screen and (max-width: 768px) {
  .ticker-wrap {
    .ticker-wrap_text {
      h2 {
        font-size: var(--size-h6);
      }
    }
  } 
}
/* End media queries  */
