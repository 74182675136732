.glitch-active {
  &::before,
  &::after {
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    font-size: var(--glitch-text);
    letter-spacing: 1px;
  }

  &::before {
    color: var(--glitch-before);
    z-index: -1;
  }

  &::after {
    color: var(--glitch-after);
    z-index: -2;
  }

  &:hover {
    &::before {
      animation: glitch-animation .8s cubic-bezier(.25, .46, .45, .94) both infinite
    }

    &::after {
      animation: glitch-animation .5s cubic-bezier(.25, .46, .45, .94) reverse both infinite
    }
  }
}

@keyframes glitch-animation {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0)
  }

  20% {
    -webkit-transform: translate(-5px, 5px);
    transform: translate(-5px, 5px);
    transform: skewX(10deg);
  }

  40% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }

  60% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px)
  }

  80% {
    -webkit-transform: translate(5px, -5px);
    transform: translate(5px, -5px)
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0)
  }
}