.product-detail {
  margin-bottom: var(--indent-gap-section);
  padding: 0 var(--indent-container);

  .product-detail-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    max-width: 1940px;
    margin: 0 auto;
  }
}

/* Start media queries  */
@media screen and (max-width: 768px) {
  .product-detail {
    margin-bottom: var(--mobile-indent-container);
  }
}
/* End media queries  */
