.socials {
  display: flex;
  gap: 20px;

  .socials-item {
    background: rgba(217, 217, 217, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.8s;
  }

  a:hover .socials-item {
    background: var(--socials-item-hover);
  }
}