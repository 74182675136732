.cursors {
  div {
    z-index: 10000;
    pointer-events: none;
    transform: translate(-50%, -50%);
    background-color: rgb(255 255 255);
    box-shadow: 0 0 5px 1px rgb(255 255 255), 0 0 14px 5px rgb(235 235 235), 0 0 3px 1px rgb(213 213 213);
    height: 1px;
    width: 1px;
    top: -20px;
    position: absolute;

    animation: leftright 20s infinite cubic-bezier(0.39, 0, 0.63, 1),
      updown 10s infinite 5s cubic-bezier(0.39, 0, 0.63, 1),
      blinking 4s infinite;

    &:nth-of-type(1) {
      animation: auto;
    }

    span {
      position: absolute;
      top: 50px;
      left: 0;
      width: max-content;
      font-family: var(--title-font);
      padding: 10px;
      font-size: var(--size-h6);
      color: var(--color-background);
      background: var(--light);

      opacity: 0;
      transition: opacity 0.5s;

      z-index: 20000;
    }
  }
}

.product-page.cursors {
  div {
    span {
      color: var(--light);
      background: var(--primary-color);
    }
  }
}

/* Animations */
@keyframes leftright {

  0%,
  100% {
    left: 80%;
  }

  16.666% {
    left: 95%;
  }

  33.333% {
    left: 10%;
  }

  50% {
    left: 60%;
  }

  66.666% {
    left: 70%;
  }

  83.333% {
    left: 5%;
  }
}

@keyframes updown {

  0%,
  100% {
    top: 10px;
  }

  25% {
    top: 90%;
  }

  50% {
    top: 50%;
  }

  75% {
    top: 95%;
  }
}

@keyframes blinking {
  0%,
  100% {
    box-shadow: 0 0 1px 1px rgb(255 255 255),
      0 0 5px 3px rgb(235 235 235),
      0 0 8px 6px rgb(213 213 213);
    height: 0;
    width: 0;
  }

  50% {
    box-shadow: 0 0 5px 2px rgb(235 235 235),
      0 0 8px 3px rgb(213 213 213),
      0 0 10px 4px rgb(235 235 235);
  }

  75% {
    box-shadow: 0 0 0px 0px rgb(255 255 255),
      0 0 0px 0px rgb(235 235 235),
      0 0 0px 0px rgb(255 255 255);
  }
}