@import '../../components/globalStyles/glitch-active.scss';

.contact-form {
  padding: 0 var(--indent-container);
  margin-bottom: var(--indent-gap-section);

  form {
    display: flex;
    flex-direction: column;
    max-width: 1480px;
    margin: 0 auto;
  }

  .contact-form-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: var(--indent-gap-section-2);
  }

  input, textarea {
    width: 100%;
    font-size: var(--size-h4);
    color: var(--light);
    background: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid var(--primary-color);
    border-radius: 0;
    padding: 20px 0;
    outline: none;
    font-family: var(--title-font);

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 100px var(--color-background) inset !important;
      -webkit-text-fill-color: var(--color-text) !important;
    }

    &:active, &:focus {
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 2px solid var(--primary-color);
    }

    &::placeholder {
      color: var(--light);
    }
  }

  textarea {
    min-height: 190px;
    margin-bottom: 30px;
  }

  .btn {
    position: relative;
    font-size: var(--size-h3);
    font-family: var(--title-font);
    color: var(--light);
    width: max-content;
    margin: var(--indent-gap-section-2) auto;
    background: transparent;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.glitch-active-btn {
  &::after,
  &::before {
    content: 'SEND';
    font-size: var(--glitch-text1);
    letter-spacing: 1px;
  }
}

.contact-form-other {
  text-align: center;
  font-size: var(--size-h4);
  font-family: var(--title-font);
  text-transform: uppercase;
  color: var(--primary-color);
  margin-bottom: var(--indent-gap-section-2);
}

.contact-form-email {
  text-align: center;
  font-size: var(--size-h4);
  font-family: var(--title-font);

  a {
    color: var(--primary-color);
  }
}

/* Start media queries  */
@media screen and (max-width: 768px) {
  .contact-form {
    padding: 0 var(--mobile-indent-container);
    margin-bottom: var(--mobile-indent-gap-section);
  
    .contact-form-inputs {
      flex-direction: column;
      margin-bottom: var(--mobile-indent-gap-section);
    }
  
    input, textarea {
      font-size: calc((100vw - 48px) / 13);
    }

    textarea {
      margin-bottom: 30px;
    }
  
    .btn {
      font-size: var(--size-h4);
      margin: var(--mobile-indent-gap-section) auto;
    }
  }

  .glitch-active-btn {
    &::after,
    &::before {
      content: 'SEND';
      font-size: var(--glitch-text2);
      letter-spacing: 0.8px;
    }
  }

  .contact-form-other {
    font-size: calc((100vw - 48px) / 13);
    margin-bottom: var(--mobile-indent-gap-section);
  }
  
  .contact-form-email {
    font-size: calc((100vw - 48px) / 13);

    h4 {
      font-size: calc((100vw - 48px) / 13);
    }
  }
}
/* End media queries  */
