.section-decr {
  text-align: center;
  max-width: 650px;
  margin: 0 auto var(--indent-gap-section) auto;
  padding: 0 var(--indent-container);
}

/* Start media queries  */
@media screen and (max-width: 768px) {
  .section-decr {
    font-size: var(--fs-mobile-text);
    margin: 0 auto var(--mobile-indent-gap-section) auto;
    padding: 0 var(--mobile-indent-container);
  }
}
/* End media queries  */
