.product-detail-section {
  .portfolio-filter_item_main {
    width: calc(50% - 15px);

    &:hover .portfolio-filter_item .portfolio-filter_item_img {
        .portfolio-filter_item_descr {
          opacity: 0.85;
        }
      }

    .portfolio-filter_item {
      height: 100%;

      .portfolio-filter_item_tag {
        padding: 10px;
        z-index: 1;
        text-transform: capitalize;
        left: 0;
        top: 0;
        opacity: 0.85;
        color: var(--color-text);

        span {
          color: var(--primary-color);
        }
      }

      .portfolio-filter_item_img {
        position: relative;
        height: auto;

        img {
          height: 800px;
        }

        .portfolio-filter_item_descr {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          background: var(--color-background);
          top: 15px;
          left: 15px;
          width: calc(100% - 30px);
          height: calc(100% - 30px);
          opacity: 0;
          transition: opacity 0.5s;

          p {
            max-width: 80%;
            text-align: center;
          }

          span {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

/* Start media queries  */
@media screen and (max-width: 1600px) {
  .product-detail-section {
    .portfolio-filter_item_main {
      .portfolio-filter_item {
        .portfolio-filter_item_tag {
          font-size: calc((100vw - 48px) / 33);
        }

        .portfolio-filter_item_img {
          height: auto;

          img {
            height: 600px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1439px) {
  .product-detail-section {
    .portfolio-filter_item_main {
      .portfolio-filter_item {
        height: 100%;

        .portfolio-filter_item_tag {
          width: 100%;
        }

        .portfolio-filter_item_img {
          height: auto;

          img {
            height: 400px;
          }

          .portfolio-filter_item_descr {
            position: static;
            width: 100%;
            height: auto;
            opacity: 1;
            padding: var(--mobile-indent-container);
  
            p {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-detail-section {
    .portfolio-filter_item_main {
      width: 100%;

      .portfolio-filter_item {
        .portfolio-filter_item_tag {
          font-size: calc((100vw - 48px) / 16);
        }
      }
    }
  }
}
/* End media queries  */
